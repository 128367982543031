<template>
  <b-row class="notification">
    <b-col>
      <PageHeader :title="$t('general.userNotifications.title')" />

      <b-row class="notification__sticky">
        <div class="notification__tabs">
          <CRadio
            v-model="selectedCategory"
            v-for="(item, index) in categories"
            :key="`${index + '-category'}`"
            :name="item.name"
            :value="item"
            group-name="filter-tab"
            class="filter-tab"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="filter-tab__item"
                :class="{ 'filter-tab__item--active': isActive }"
              >
                <span
                  class="filter-tab__font"
                  :class="{ 'filter-tab__font--white': isActive }"
                >
                  {{ tab.name }}
                </span>
              </div>
            </template>
          </CRadio>
        </div>
      </b-row>

      <b-row>
        <InfinityScroll
          v-if="notificationsList.length"
          :items="notificationsList"
          :has-more="hasMorePosts"
          :initial-page="initialPage"
          @rich-end="loadMore"
        >
          <template #default="{ item }">
            <b-link class="notification__item" :to="item.url">
              <b-avatar
                :to="item.items.user.url"
                :text="item.items.user.initials"
                :src="item.items.user.avatar"
                size="45px"
              />
              <div class="flex-grow-1 ml-2 overflow-hidden">
                <div class="d-flex nowrap w-100">
                  <span v-if="item.items.user.username" class="notification__font notification__font--user">
                    {{ "@" + item.items.user.username }}
                  </span>
                  <span v-else class="notification__font notification__font--user">
                    {{ $t('general.deletedUser') }}
                  </span>
                </div>

                <div class="notification__description">
                  <span
                    class="notification__font notification__font--description"
                  >
                    {{ $t(item.description[0], item.description[1]) }}
                    <span class="notification__font notification__font--ago">
                      {{ item.ago }}
                    </span>
                  </span>
                </div>
              </div>
            </b-link>
          </template>
        </InfinityScroll>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Notification from "@/components/models/Notification";
import PageHeader from "@/layout/PageHeader.vue";
import CRadio from "@/features/ui/CRadio.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

import { mapGetters, mapMutations } from "vuex";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  components: { InfinityScroll, CRadio, PageHeader },
  mixins: [waitRequest, validationError],
  data: function () {
    return {
      nextPage: 1,
      initialPage: 1,

      selectedCategory: {
        id: 1,
        name: this.$t("general.userNotifications.radio.all"),
        key: "all",
      },
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notifications/notifications",
      notificationsMeta: "notifications/notificationsMeta",
    }),

    categories() {
      return [
        {
          id: 1,
          name: this.$t("general.userNotifications.radio.all"),
          key: "all",
        },
        {
          id: 2,
          name: this.$t("general.userNotifications.radio.likes"),
          key: Notification.TYPE_LIKE,
        },
        {
          id: 3,
          name: this.$t("general.userNotifications.radio.comments"),
          key: Notification.TYPE_COMMENT,
        },
        {
          id: 4,
          name: this.$t("general.userNotifications.radio.mentions"),
          key: Notification.TYPE_COMMENT_LIKE,
        },
        {
          id: 5,
          name: this.$t("general.userNotifications.radio.followers"),
          key: Notification.TYPE_SUBSCRIBE,
        },
      ];
    },
    hasMorePosts() {
      return Boolean(this.notificationsMeta?.next_page_url);
    },

    notificationsList() {
      const list =
        this.notifications.map((post) => new Notification(post)) || [];

      return list.filter((notification) => {
        if (this.selectedCategory.key === "all") return notification;

        return notification.type === this.selectedCategory.key;
      });
    },

    page() {
      return this.notificationsMeta?.current_page || 1;
    },
  },

  mounted() {
    this.initialLoadPost();
  },
  methods: {
    ...mapMutations({
      setPosts: "notifications/setPosts",
      updatePosts: "notifications/updatePosts",
      setMeta: "notifications/setMeta",
    }),

    loadPosts(successCallback) {
      if (this.requestInProgress) return;

      const url = new URL(`/notifications`, process.env.VUE_APP_API_URL);
      url.searchParams.set("page", this.nextPage);

      const requestUrl = url.pathname + url.search;

      this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadPost() {
      this.$showSpinner();

      this.loadPosts(this.setCurrentPosts);
    },

    loadMore() {
      this.nextPage = this.nextPage + 1;
      if (this.hasMorePosts) return this.loadPosts(this.updateCurrentPosts);
    },

    setCurrentPosts(data) {
      const { current_page, data: posts } = data;

      this.setPosts({ posts });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const posts = data.data;

      this.updatePosts({ posts });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
.link {
  color: $black;
  text-decoration: none;
  &:hover {
    color: $black;
    text-decoration: none;
    background-color: var(--light);
  }
}

.notification {
  &__item {
    display: flex;
    width: 100%;
    padding: em(12) em(17);

    color: $black;
    text-decoration: none;
    &:hover {
      color: $black;
      text-decoration: none;
      background-color: var(--light);
    }
  }

  &__sticky {
    position: sticky;
    top: em(57);
    z-index: 1020;
    background-color: $white;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;

    @include hideScroll;
    padding: em(12) em(17);
  }

  &__description {
    margin-top: em(6);
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-style: normal;
    font-size: em(14);
    font-weight: 500;
    line-height: normal;

    &--user {
      font-size: em(14);
    }

    &--description {
      color: $app-gray-22;
      font-weight: 400;
    }

    &--ago {
      color: $app-gray-23;
      font-weight: 400;
    }
  }
}

.filter-tab {
  max-height: em(19);

  &:not(:first-child) {
    margin-left: em(5);
  }

  &__item {
    display: inline-flex;
    padding: em(5) em(9);
    background-color: $app-border-gray-1;
    border-radius: em(100);

    &--active {
      background-color: $black;
      color: $white;
    }
  }

  &__font {
    color: $app-gray-19;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--white {
      color: $white;
    }
  }
}
</style>
